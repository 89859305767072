import React, { useEffect, useState, useContext, useRef } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {
  notification,
  Table,
  Image,
  Spin,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tag,
  Space,
} from "antd";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import axios from "axios";

const SuperAdminSetting = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const systemRoleFormRef = useRef();
  //SystemRoles
  const [systemRoles, setSystemRoles] = useState([]);
  const [isSystemRole, setIsSystemRole] = useState(false);
  const [isEditSystemRole, setIsEditSystemRole] = useState(false);
  const [systemRoleForm] = Form.useForm();
  const [insertVariableIds, setInsertVariableIds] = useState([]);

  //InsertVariables
  const [insertVariables, setInsertVariables] = useState([]);
  const [isInsertVariable, setIsInsertVariable] = useState(false);
  const [isEditInsertVariable, setIsEditInsertVariable] = useState(false);
  const [insertVariableForm] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [newOptionLabel, setNewOptionLabel] = useState("");
  const [newOptionValue, setNewOptionValue] = useState("");
  const [selectedType, setSelectedType] = useState({});
  const hiddenFileInput = useRef(null);
  const [previewSource, setPreviewSource] = useState();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        await Promise.all([getSystemRoles(), getInsertVariables()]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) {
      systemRoleForm.setFieldsValue({ icon: "" });
      setPreviewSource(null);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      systemRoleForm.setFieldsValue({ icon: reader.result });
      setPreviewSource(reader.result);
    };
  };

  const getSystemRoles = async () => {
    try {
      const response = await axios.get(webAPIs.getSystemRoles);
      if (response.data.success) {
        setSystemRoles(response.data.data);
      }
    } catch (e) {
      console.log(e.response?.data?.error);
      notification.error({
        description: e.response?.data?.error || "Server Error",
        duration: 2,
      });
    }
  };

  const getInsertVariables = async () => {
    try {
      const response = await axios.get(webAPIs.getInsertVariables);
      if (response.data.success) {
        setInsertVariables(response.data.data);
      }
    } catch (e) {
      console.log(e.response?.data?.error);
      notification.error({
        description: e.response?.data?.error || "Server Error",
        duration: 2,
      });
    }
  };

  const systemRolesTableColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (data) => <Image width={100} src={data} />,
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
    },
    {
      title: "SystemPrompt",
      dataIndex: "systemPrompt",
      key: "systemPrompt",
    },
    {
      title: "InsertVariables",
      dataIndex: "insertVariableIds",
      key: "insertVariableIds",
      render: (data) =>
        data.map((option) => {
          return (
            <Tag key={option?.label} color="green" className="mb-2">
              {option?.label}
            </Tag>
          );
        }),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button
            className="bg-sidebarColor text-white"
            onClick={() => handleSystemRoleChange(record)}
          >
            Change
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              handleSystemRoleRemove(record);
            }}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const insertVariablesTableColumns = [
    { title: "Label", dataIndex: "label", key: "label" },
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: (options) =>
        options.map((option) => {
          let color = option?.label?.length > 5 ? "geekblue" : "green";
          return (
            <Tag key={option?.value} color={color} className="mb-2">
              {option?.label}: {option?.value}
            </Tag>
          );
        }),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button
            className="bg-sidebarColor text-white"
            onClick={() => handleInsertVariableChange(record)}
          >
            Change
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              handleInsertVariableRemove(record);
            }}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const handleInsertVariableChange = (variable) => {
    setIsInsertVariable(true);
    insertVariableForm.setFieldsValue({
      id: variable.id,
      label: variable.label,
      type: variable.type,
      options: variable.options,
      title: variable.title,
    });
    setOptions(variable.options);
    setIsEditInsertVariable(true);
  };

  const handleInsertVariableRemove = async (variable) => {
    try {
      const response = await axios.post(webAPIs.deleteInsertVariable, {
        user: user.id,
        id: variable.id,
      });
      if (response.data.success) {
        getInsertVariables();
        notification.success({
          description: response.data.message,
          duration: 2,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.error || "server Error",
        duration: 2,
      });
    }
  };

  const handleSystemRoleChange = (variable) => {
    setIsSystemRole(true);
    systemRoleForm.setFieldsValue({
      id: variable.id,
      title: variable.title,
      icon: variable.icon,
      caption: variable.caption,
      systemPrompt: variable.systemPrompt,
      insertVariableIds: variable.insertVariableIds,
    });
    setInsertVariableIds(variable.insertVariableIds);
    setIsEditSystemRole(true);
  };

  const handleSystemRoleRemove = async (variable) => {
    try {
      const response = await axios.post(webAPIs.deleteSystemRole, {
        user: user.id,
        id: variable.id,
      });
      if (response.data.success) {
        getSystemRoles();
        notification.success({
          description: response.data.message,
          duration: 2,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.error || "server Error",
        duration: 2,
      });
    }
  };

  const TypeOptions = [
    { label: "Input", value: "input" },
    { label: "Radio", value: "radio" },
    { label: "Dropdown", value: "dropdown" },
  ];

  const addOption = () => {
    if (newOptionLabel && newOptionValue) {
      const newOptions = [
        ...options,
        { label: newOptionLabel, value: newOptionValue },
      ];
      setOptions(newOptions);
      setNewOptionLabel("");
      setNewOptionValue("");
      insertVariableForm.setFieldsValue({ options: newOptions });
    }
  };

  const removeOption = (label) => {
    const filteredOptions = options.filter((option) => option.label !== label);
    insertVariableForm.setFieldsValue({ options: filteredOptions });
    setOptions(filteredOptions);
  };

  const replaceVariablesWithValues = (text) => {
    const matches = text.match(/\{([^}]+)}/g) || [];
    const uniqueIds = new Set([]);
    matches.forEach((item) => {
      const labelWithoutBraces = item.replace(/^\{|\}$/g, "");
      const foundVariable = insertVariables.find(
        (variable) => variable.label === labelWithoutBraces
      );

      if (foundVariable) {
        uniqueIds.add(foundVariable);
      }
    });
    setInsertVariableIds([...uniqueIds]);
    systemRoleForm.setFieldsValue({ insertVariableIds: [...uniqueIds] });
  };

  const handleInsertVariableOk = () => {
    insertVariableForm
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          if (isEditInsertVariable) {
            const response = await axios.post(webAPIs.editInsertVariable, {
              user: user.id,
              id: values.id,
              type: values.type,
              options: values.options || [],
              label: values.label,
              title: values.title,
            });
            if (response.data.success) {
              notification.success({
                description: response.data.message,
                duration: 2,
              });
            }
          } else {
            const response = await axios.post(webAPIs.addInsertVarialble, {
              user: user.id,
              type: values.type,
              options: values.options || [],
              label: values.label,
              title: values.title,
            });
            if (response.data.success) {
              notification.success({
                description: response.data.message,
                duration: 2,
              });
            }
          }
          setLoading(false);
          getInsertVariables();
          setIsEditInsertVariable(false);
          insertVariableForm.resetFields();
          setIsInsertVariable(false);
          setOptions([]);
        } catch (error) {
          setLoading(false);
          notification.error({
            description: error.response?.data?.error || "Server Error",
            duration: 2,
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleSystemRoleOk = () => {
    systemRoleForm
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          if (isEditSystemRole) {
            const response = await axios.post(webAPIs.editSystemRole, {
              user: user.id,
              id: values.id,
              title: values.title,
              icon: values.icon,
              caption: values.caption,
              systemPrompt: values.systemPrompt,
              insertVariableIds: values.insertVariableIds,
            });
            if (response.data.success) {
              notification.success({
                description: response.data.message,
                duration: 2,
              });
            }
          } else {
            const response = await axios.post(webAPIs.addSystemRole, {
              user: user.id,
              title: values.title,
              icon: values.icon,
              caption: values.caption,
              systemPrompt: values.systemPrompt,
              insertVariableIds: values.insertVariableIds,
            });
            if (response.data.success) {
              notification.success({
                description: response.data.message,
                duration: 2,
              });
            }
          }
          setLoading(false);
          getSystemRoles();
          setIsEditSystemRole(false);
          systemRoleForm.resetFields();
          setPreviewSource("");
          setIsSystemRole(false);
          setInsertVariableIds([]);
        } catch (error) {
          setLoading(false);
          notification.error({
            description: error.response?.data?.error || "Server Error",
            duration: 2,
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex w-full h-full justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="w-full flex flex-col p-5 pt-20 gap-20">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <span>Insert Variables</span>
              <Button
                className="w-[20%] flex items-center justify-center text-white bg-sidebarColor"
                onClick={() => {
                  insertVariableForm.resetFields();
                  setOptions([]);
                  setIsEditInsertVariable(false);
                  setIsInsertVariable(true);
                }}
              >
                add insertVariable
              </Button>
            </div>
            <Table
              dataSource={insertVariables}
              columns={insertVariablesTableColumns}
              pagination={{ defaultPageSize: 10 }}
            />
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <span>System Roles</span>
              <Button
                className="w-[20%] flex items-center justify-center text-white bg-sidebarColor"
                onClick={() => {
                  systemRoleForm.resetFields();
                  setPreviewSource("");
                  setInsertVariableIds([]);
                  setIsEditSystemRole(false);
                  setIsSystemRole(true);
                }}
              >
                add systemRole
              </Button>
            </div>
            <Table
              dataSource={systemRoles}
              columns={systemRolesTableColumns}
              pagination={{ defaultPageSize: 10 }}
            />
          </div>
        </div>
      )}
      <Modal
        okButtonProps={{ className: "bg-[#007bff]" }}
        title="Add InsertVariable"
        open={isInsertVariable}
        confirmLoading={loading}
        onOk={handleInsertVariableOk}
        onCancel={() => {
          setIsInsertVariable(false);
          setOptions([]);
        }}
        width="80%"
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          form={insertVariableForm}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="id" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                label="Label"
                name="label"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ require: true, message: "please insert title" }]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select type!",
                  },
                ]}
              >
                <Select
                  options={TypeOptions}
                  onChange={(val) => setSelectedType(val)}
                />
              </Form.Item>
              {selectedType !== "input" && (
                <Form.Item
                  label="Options"
                  name="options"
                  rules={[
                    {
                      required: true,
                      message: "Please input options!",
                    },
                  ]}
                >
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-5">
                      <Input
                        placeholder="label"
                        value={newOptionLabel}
                        onChange={(e) => setNewOptionLabel(e.target.value)}
                      />
                      <Input
                        placeholder="value"
                        value={newOptionValue}
                        onChange={(e) => setNewOptionValue(e.target.value)}
                      />
                      <Button
                        className="bg-[#007bff] text-white"
                        onClick={addOption}
                      >
                        Add
                      </Button>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {options.map((option) => (
                        <Tag
                          key={option.label} // Assume the labels are unique
                          closable
                          closeIcon={<CloseOutlined />}
                          onClose={() => removeOption(option.label)}
                          className="max-w-xs"
                        >
                          {option.label}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        okButtonProps={{ className: "bg-[#007bff]" }}
        title="Add SystemRole"
        open={isSystemRole}
        confirmLoading={loading}
        onOk={handleSystemRoleOk}
        onCancel={() => {
          setIsSystemRole(false);
          setInsertVariableIds([]);
        }}
        width="80%"
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          form={systemRoleForm}
          ref={systemRoleFormRef}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="id" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Icon"
                name="icon"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <div className="flex flex-col gap-3">
                  <input
                    type="file"
                    className="hidden"
                    ref={hiddenFileInput}
                    onChange={handleFileInputChange}
                  />
                  {previewSource && (
                    <img
                      src={previewSource}
                      alt="chosen"
                      className="w-24 h-24"
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item
                label="Caption"
                name="caption"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="SystemPrompt"
                name="systemPrompt"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  onChange={(e) => {
                    replaceVariablesWithValues(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="InsertVariableIds" name="insertVariableIds">
                <div className="flex flex-wrap gap-2">
                  {insertVariableIds?.map((option) => (
                    <Tag key={option.label} className="max-w-xs">
                      {option.label}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SuperAdminSetting;
