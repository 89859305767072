import React, { useState, useEffect, createContext } from "react";
import { webAPIs } from "./config/webAPIs";
import axios from "axios";
import { notification } from "antd";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([
    {
      role: "assistant",
      content: "Hello. How can I help you?",
    },
  ]);
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = useState(true);
  const [adminPrompt, setAdminPrompt] = useState("");
  const [kSystemPrompt, setKSystemPrompt] = useState("");
  const [kUserPrompt, setKUserPrompt] = useState("");
  const [kFontSize, setKFontSize] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [systemRoles, setSystemRoles] = useState([]);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const email = JSON.parse(localStorage.getItem("user"));
    if (auth && email) {
      refreshUserData(email); // fetch fresh data from backend
    } else {
      setIsLoading(false);
    }
  }, []);

  const refreshUserData = async (email) => {
    try {
      const { data } = await axios.post(webAPIs.getUser, { email });

      if (data.success) {
        setUser(data.user);
        setLoggedIn(true);
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      handleResetMessage();
    }
  }, [loggedIn]);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      const typingTimer = setTimeout(() => {
        handleChangeAdminPrompt();
      }, 500);
      return () => clearTimeout(typingTimer);
    }
  }, [adminPrompt, inputValue, checked, kSystemPrompt, kUserPrompt, kFontSize]);

  const handleChangeAdminPrompt = async () => {
    if (adminPrompt) {
      if (user) {
        await axios.post(webAPIs.changeSystemPrompt, {
          user: user.id,
          systemPrompt: adminPrompt,
          temp: inputValue,
          kSystemPrompt,
          kUserPrompt,
          kFontSize,
          checked,
        });
      }
    }
  };

  const handleResetMessage = async () => {
    if (user) {
      const response = await axios.post(webAPIs.getConversation, {
        user: user.id,
      });
      if (response.data.success) {
        setMessage(response.data.result.conversation);
        setAdminPrompt(response.data.result.systemPrompt);
        setInputValue(response.data.result.temp);
        setFiles(response.data.result.files);
        setChecked(response.data.result.checked);
        setKSystemPrompt(response.data.result.kSystemPrompt);
        setKUserPrompt(response.data.result.kUserPrompt);
        setKFontSize(response.data.result.kFontSize);
      }
      getSystemRoles();
    }
  };

  const getSystemRoles = async () => {
    try {
      const response = await axios.get(webAPIs.getSystemRoles);
      if (response.data.success) {
        setSystemRoles(response.data.data);
      }
    } catch (e) {
      notification.error({
        description: e.response?.data?.error || "Server Error",
        duration: 2,
      });
    }
  };

  const login = (userData) => {
    setUser(userData);
    setLoggedIn(true);
    localStorage.setItem("auth", JSON.stringify(true));
    localStorage.setItem("user", JSON.stringify(userData.email));
  };

  const updateUser = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    localStorage.setItem("auth", JSON.stringify(false));
    localStorage.setItem("user", JSON.stringify(""));
    setLoggedIn(false);
    setUser(null);
    window.open("/", "_self");
  };

  return (
    <div className="bg-botColor h-full">
      <AuthContext.Provider
        value={{
          loggedIn,
          user,
          isLoading,
          login,
          logout,
          updateUser,
          message,
          setMessage,
          files,
          setFiles,
          checked,
          setChecked,
          adminPrompt,
          setAdminPrompt,
          kSystemPrompt,
          setKSystemPrompt,
          kUserPrompt,
          setKUserPrompt,
          kFontSize,
          setKFontSize,
          inputValue,
          setInputValue,
          systemRoles,
          setSystemRoles,
          handleResetMessage,
          setLoading,
          loading
        }}
      >
        {children}
      </AuthContext.Provider>
    </div>
  );
};

export { AuthContext, AuthProvider };
