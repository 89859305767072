import React, { useState, useContext, useEffect } from "react";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";

import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import axios from "axios";

const Login = () => {
  const { login } = useContext(AuthContext);
  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      navigate("/dashboard");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post(webAPIs.signIn, { email, password });
      if (data.success) {
        localStorage.setItem("auth", true);
        localStorage.setItem("user", JSON.stringify({ email: email }));
        login(data.user);
        if (data.user.systemRole) {
          navigate("/dashboard");
        } else {
          navigate("/dashboard/onboarding");
        }
      } else {
        notification.error({
          message: "Login Error",
          description: data.message,
          duration: 5,
        });
      }
    } catch (error) {
      notification.error({
        message: "API Error",
        description: error.response.data.message,
        duration: 5,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-intro">
      <div className="logo-area w-full max-w-md">
        <img
          src={`${process.env.PUBLIC_URL}/insightlab_gold.png`}
          alt="logo"
          className="hidden sm:block"
        />
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
          className="block sm:hidden p-2"
        />
      </div>
      <div className="bg-login p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-10 text-gray-800">Sign In</h2>
        <form className="space-y-6" onSubmit={handleFormSubmit}>
          <div>
            <label className="text-sm font-bold text-gray-600 block">
              Email address
            </label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <label className="text-sm font-bold text-gray-600 block">
              Password
            </label>
            <input
              type="password"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-800"
              >
                Remember me
              </label>
            </div>
            {/* Uncomment below if you have a password reset flow */}
            <div className="text-sm">
              <Link to="/forgot-password" className="font-medium link-text">
                Forgot your password?
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="submit-btn w-full py-2 px-4 rounded-md text-white text-sm font-bold"
            >
              Sign In
            </button>
          </div>
        </form>
        <div className="text-center mt-6">
          Don't have an account?
          <Link to="/sign-up" className="link-text hover:underline">
            {" "}
            Sign up
          </Link>
        </div>
      </div>
      <Footer currentPage="Login" />
    </div>
  );
};

export default Login;
