import React, { useState, useContext, useMemo, useEffect } from "react";
import AdminActive from "../assets/Icons/Admin-active.png";
import Admin from "../assets/Icons/Admin.png";
import ChangeRoleActive from "../assets/Icons/Change-role-active.png";
import ChangeRole from "../assets/Icons/Change-role.png";
import ChatActive from "../assets/Icons/Chat-active.png";
import Chat from "../assets/Icons/Chat.png";
import Logout from "../assets/Icons/Logout.png";
import LogoutActive from "../assets/Icons/Logout-active.png";
import TeamsActive from "../assets/Icons/Teams-active.png";
import Teams from "../assets/Icons/Teams.png";
import Tutorial from "../assets/Icons/Tutorials.png";
import TutorialActive from "../assets/Icons/Tutorials-active.png";

import { AuthContext } from "../AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const { logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(location.pathname.split("/").pop());
    const lastIndex = location.pathname.split("/").pop();
    if (lastIndex === "onboarding") setActiveItem(0);
    else if (lastIndex === "controluser") setActiveItem(2);
    else if (lastIndex === "admin") setActiveItem(4);
    else setActiveItem(1);
  }, [location.pathname]);

  const imagesData = [
    {
      src: ChangeRole,
      activeSrc: ChangeRoleActive,
      alt: "Change Role",
      onClick: () => navigate("onboarding"),
    },
    {
      src: Chat,
      activeSrc: ChatActive,
      alt: "Chat",
      onClick: () => navigate("/dashboard"),
    },
    ...(user && user.role !== 0
      ? [
          {
            src: Teams,
            activeSrc: TeamsActive,
            alt: "Teams",
            onClick: () => navigate("controluser"),
          },
        ]
      : []),
    {
      src: Tutorial,
      activeSrc: TutorialActive,
      alt: "Tutorials",
      onClick: () => window.open("https://www.insight-lab.ai/help", "_blank"),
    },
    {
      src: Admin,
      activeSrc: AdminActive,
      alt: "Admin",
      onClick: () => navigate("admin"),
    },
    {
      src: Logout,
      activeSrc: LogoutActive,
      alt: "Log Out",
      onClick: logout,
    },
  ];

  const handleItemClick = (index, onClick) => {
    setActiveItem(index);
    onClick();
  };

  const handleItemHover = (index) => {
    if (!isMobile) {
      setHoveredItem(index);
    }
  };


  const handleItemLeave = () => {
    if (!isMobile) {
      setHoveredItem(null);
    }
  };

  const handleItemTap = (index, onClick) => {
    if (isMobile) {
      setActiveItem(index);
      onClick();
    }
  };

  const NavigationItem = ({ images }) => (
    <>
      {images.map((image, index) => (
        <div
          key={index}
          className="flex flex-col items-center sm:mt-10 mt-1 cursor-pointer"
          onClick={() => handleItemClick(index, image.onClick)}
          onTouchStart={() => handleItemTap(index, image.onClick)}
          onMouseEnter={() => handleItemHover(index)}
          onMouseLeave={() => handleItemLeave()}
        >
          <img
            src={
              hoveredItem === index || activeItem === index
                ? image.activeSrc
                : image.src
            }
            alt={image.alt}
            className={`transition-all ${isMobile ? "w-6 h-6" : "w-12 h-12"}`}
          />
          <div
            className={`nav-items mt-1.5 ${
              hoveredItem === index || activeItem === index
                ? "text-[#D8AE5F]"
                : "text-white"
            }`}
          >
            {image.alt}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <nav className="flex flex-col px-2 sm:py-5 py-2 text-xs font-extrabold tracking-normal leading-loose text-center whitespace-nowrap bg-zinc-700 text-neutral-200 w-full h-full overflow-x-auto">
      <div className="flex sm:flex-col items-center h-full justify-between">
        <NavigationItem images={imagesData} />
      </div>
    </nav>
  );
};

export default Navbar;
