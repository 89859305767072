import React, { useState, useMemo, useEffect } from "react";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import {
  notification,
  Checkbox,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import { webAPIs } from "../config/webAPIs";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countryList from "react-select-country-list";

const { Option } = Select;

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      country: "CH",
      agree: false,
    });
  }, [form]);

  const handleFormSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      notification.error({
        message: "Password Error",
        description: "Passwords do not match!",
        duration: 5,
      });
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await axios.post(webAPIs.signUp, {
        ...values,
      });
      setIsLoading(false);
      if (data.success) {
        form.resetFields();
        navigate("/sign-in");
        notification.success({
          message: "Success",
          description: data.message,
          duration: 5,
        });
      } else {
        notification.error({
          message: "Registration Error",
          description: data.message,
          duration: 5,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: "API Error",
        description: error.response?.data?.message || "An error occurred",
        duration: 5,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-intro">
      <div className="logo-area w-full max-w-md">
        <img
          src={`${process.env.PUBLIC_URL}/insightlab_gold.png`}
          alt="logo"
          className="hidden sm:block"
        />
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
          className="block sm:hidden p-2"
        />
      </div>
      <div className="bg-login register-form p-8 rounded-lg shadow-lg w-full max-w-xl mx-auto">
        <h2 className="text-2xl font-semibold mb-6 text-gray-900 text-center">
          Create Your Account
        </h2>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Company Name" name="companyName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Street" name="street">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please input your city!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Postal Code"
                name="postalCode"
                rules={[
                  { required: true, message: "Please input your postal code!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Please select your country!" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue="CH"
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <PhoneInput
                  international
                  defaultCountry="CH"
                  placeholder="Enter phone number"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>

            <Form.Item
              name="agree"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "You must agree with the terms and conditions."
                          )
                        ),
                },
              ]}
            >
              <Checkbox>
                I agree with the{" "}
                <Typography.Link
                  href="https://www.insight-lab.ai/terms_and_conditions"
                  target="_blank"
                >
                  terms and conditions
                </Typography.Link>
              </Checkbox>
            </Form.Item>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="submit-btn"
                >
                  Register
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="text-center mt-4">
          Already have an account?
          <Link to="/sign-in" className="link-text hover:underline ml-1">
            Sign in
          </Link>
        </div>
      </div>
      <Footer currentPage="Register" />
    </div>
  );
};

export default Register;
